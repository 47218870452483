<template lang="html">
  <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
    <div class="inner">
      <p class="eula">
        <b style="text-align:center;">最终用户许可协议</b> <br><br>
        <b style="text-align:center;">生效日期:2020 年 7 月 3 日</b> <br><br>
        <b>定义</b> <br><br>
        请仔细阅读本最终用户许可协议(以下简称“协议”)，勾选“同意”后，方可下载或者使用 neo 系列产品(包括 neo Portal, neo Study 程序和 neo LIVE)、 neo 课程、neo 网页和 neo 其 他服务 (应用程序)。Nexgen English Online Co. (以下简称“Nexgen”)于美国加利福尼 亚州注册，是 neo 系列产品的所有者。 本协议是您(个人或单一实体)与 Nexgen 之间签 订得合法协议，确保您对 neo 系列应用程序和课程，以及 Nexgen 提供得其他服务的使用。 点击“同意”下载或者使用应用程序，即视为您同意并接受本协议中的各项服务条款的内容。 如您不同意本协议条款，请不要勾选“同意”，主动取消下载和使用 neo 相关产品和服务。

        <br><br><b>授权许可</b><br><br>
        本系列应用程序是由 Nexgen 授权许可(而非出售)给您使用的。 Nexgen 授予您可撤销 的、非独家的、不可转让的有限许可，请严格遵守本协议的条款进行下载、安装和使用应用 程序。本应用程序仅供个人使用，不可转让。注册时，系统会对您的账号身份进一步核实。 如果您发现有人未经授权使用您的帐户，请立即通知 Nexgen。 Nexgen 对于因您不遵守此 规定而引起的任何损失或损害不承担任何责任。本协议包含了您对所购产品的所有使用权 限，Nexgen 将保留本协议中未明确授予您的所有权利。未经授权，任何人不得复制、分发 或利用任何与产品相关的内容、代码、数据、材料或设计，包括但不限于其中的版权、专利 权、数据库权或任何其他知识产权和专有权。使用 Nexgen 应用程序、课件或服务不会授予 您任何与 Nexgen 相关的内容、代码、数据或资料的所有权。未经 Nexgen 书面同意，任何 人不得以任何形式分发、出版或使用 Nexgen 应用程序。根据美国和国际版权法或其他任何 联邦或州法律、版权法或其他国家/地区的其他适用法律，本协议中的任何内容都不构成
        Nexgen 对产品专有权的放弃。

        <br><br><b>第三方服务</b><br><br>
        本应用程序可能呈现、包含或提供第三方内容(包括数据、信息、应用程序和其他产品服务)， 或提供指向第三方网站或服务的链接(第三方服务)。 用户需要理解并确认，Nexgen 不会 对任何第三方服务负责，包括其准确性、完整性、及时性、有效性、版权合规性、合法性、 合宜性和质量等任何其他方面。 对于任何第三方服务，Nexgen 对您或任何其他个人或实 体不承担任何责任。Nexgen 仅提供访问和使用第三方服务的便利，您需要完全自担风险， 并接受第三方服务的条款和条件的约束。

        <br><br><b>服务条款</b><br><br>
        1. 您将使用 neo 系统学习并完成与欧洲语言教学共同参考框架(CEFR)对应级别的英语学 习，获得与 CEFR 相应级别的英语证书。<br>
        2. 您需要获得所在级别要求的学习得分和面授课得分。学习得分通过程序学习获得。学习 得分的多少取决于该证书级别的学习要求，您可以在程序中查看。建议您每学习两周后进行 一次在线面授课。每次课程您都将获得面授课得分。面授课得分要求可以在应用程序和 Nexgen 的常见问题解答(FAQ)中找到。<br>
        3. 完成学习得分和面授课得分要求后，Nexgen 会提示您在学习应用程序中进行该级别的 结业测试。此时，您可以选择直接参加结业测试或者先复习课程来为结业测试做好充足准备。<br>
        4. 如果您未通过结业测试，Nexgen 将要求您复习学习程序内的课程。结业测试会在您完成 所有要求的复习课后再次打开。一个级别的结业测试最多打开 3 次。<br>
        5. 一旦您通过所在级别的结业测试，Nexgen 将为您颁发相应级别的英语证书。<br>
        6. 您可以通过应用程序获得您的证书，还可选择打印证书以提交给您的学校、大学或公司。<br>
        7. Nexgen 与 CEFR 级别对应的证书是由位于加利福尼亚州圣何塞的教育公司 DynEd
           International 颁发的。<br>
        8. Nexgen 针对在线面授课提供了课前模拟器(Session Simulator)，这是一款模拟测试 在线面授课环境的专用工具。请务必运行这一步，以确保您的网络连接足以支持在线面授课 中的连接和交互。此步骤应在预订面授课将发生的环境中执行。如果出现网络连接无法提供 足够的带宽或稳定性的情况，我们建议您切换到更快或更稳定的网络。如果在课程进行时网 络连接出现问题，我方不承担任何责任。<br>
        9. 如果您预约地面授课老师未在规定的时间内出现，或者迟到了五分钟及以上，Nexgen 将退还您为该面授课支付给老师的代币(token)，并且该次面授课中剩余的时间是免费的。<br>

        <br><b>证书承诺</b><br><br>
        1. Nexgen 郑重承诺，通过一段时间的学习，您可以成功通过结业测试，并获得与 CEFR 等 级相对应的证书。请参阅 Nexgen 的常见问题解答(FAQ)，查看各级别所需的学习时间。<br>
        2. 要获得认证，您必须每周达到预先设定的最低分，并且完成每个级别要求的面授课。有 关学习得分和面授课的要求，请参阅 Nexgen 的常见问题解答(FAQ)。<br>
        3. 如果您在承诺日期的最后或前一天未通过该级别的首次结业测试，Nexgen 将为您再免 费提供 4 个代币，方便您通过自己帐号预订在线面授课，以更好地准备第二次的结业测试。<br>
        4. Nexgen 仅针对首次未通过该级别结业测试的用户提供免费的在线面授课代币。随后相同 级别结业测试的再次失败将不会获得额外的代币。如果您已满足所有学习要求，但结业测试 仍未通过，Nexgen 将与您联系，安排专业的测试老师与您进行单独的在线测试。<br>
        5. 如果您未在规定学习时间内进行结业测试且首次尝试未通过，Nexgen 将不会为您提供额 外的在线面授课代币。<br>

        <br><b>隐私政策</b><br><br>
        Nexgen 隐私政策涵盖了您与 Nexgen 互动时的个人信息的收集和使用。例如，当您访问我
        们的网站时，当您使用 Nexgen 产品和服务时，或当您与我们的客户支持联系时。<br>
        1. 我们为什么收集您的个人信息?Nexgen 收集您的个人信息旨在提供更高水平的服务和 支持。此外，您的个人信息有助于使您随时了解产品公告、更新和特别优惠。如果您订阅了 Nexgen 电子邮件提醒，Nexgen 则可能会定期向您发送有关产品和服务的最新信息。您可 以随时选择退订。<br>
        2. 我们收集什么信息?Nexgen 会根据您的个人信息，通过多种方式为您提供更好的服务。 在这种情况下，我们收集到您的相关信息可能包括姓名、电话号码、电子邮件、您目前使用 neo 产品的相关信息(如购买日期等)以及与支持或服务有关的信息。 当您购买 neo 产品 时，我们可能会从第三方获取您的相关信息，比如您使用的付款方式以及您的发卡行或金融 机构的身份。<br>
        3. 我们何时披露您的信息?Nexgen 非常重视您的隐私保护。当您使用 neo 产品或服务时， Nexgen 会保护您的信息。除非得到您的同意或法律要求，我们不会与 Nexgen 以外的任何 公司、组织或个人共享您的个人信息。<br>
        4. 我们如何保护您的个人信息?Nexgen 会采取预防措施，包括行政、技术和物理措施，以 保护您的个人信息免遭丢失、盗窃、滥用以及未经授权的访问、泄露、更改和破坏。<br>
        <!-- 5. Region specific privacy and data compliance? Nexgen products and services are completely in compliance, at the time of the Effective Date, with the following Data Privacy regulations: GDPR, FERPA, COPPA, CDPL, PDPA, DPL, PDPL, and PDPO. For more information please see Contact Information.<br> -->

        <br><b>注册及要求</b><br><br>
        1. 您需要注册并支付一定的费用才可以获取我们 neo APP 的使用权限。在此过程中，需要 提供您的个人信息和数据(“个人信息”)。<br>
        2. 在付费之前，您需要购买您下一个目标级别的证书，这将决定您需要支付的费用。<br>
        3. 完成注册并支付费用后，您需要创建 neo 通用账号，以方便您使用 neo 所提供的生态系 统服务。<br>
        4. 为确保学习正常进行，您需要下载 APP 到您的设备并确保以下运行条件:<br>

        <div style="margin:0 0 0 20px;">
          4.1 设备的内存等于或大于 2GB。<br>
          4.2 苹果设备系统版本为 10.0 或更高版本，安卓设备系统版本为 4.1 或更高版本。<br>
          4.3 为确保语音识别练习的正常进行，需配备具有麦克风功能的耳麦。<br>
          4.4 能精确且准确设置时间的设备。<br>
        </div>

        5. 通过注册、支付费用获得学习账号后，根据您所选择的学习方案，您将获得相应数量的 线上面授课代币。我们的面授课老师接受过统一培训，您可以在 APP 中进行预约。<br>
        <div style="margin:0 0 0 20px;">
          5.1 neo 根据每个级别所需的面授课发放代币。如果您未在预定时间内完成在线面授课而 导致未获得面授课得分，我们不承担任何责任。这种情况下，为确保您达到线上面授课得分 要求，您需要自行购买代币。<br>
          5.2 neo 提供的代币以 8 的倍数为售卖单位。如果在学习期间您购买了过多的代币，完成 级别学习后未使用的代币将储存在您的学习账号中，您可以自由支配。<br>
          5.3 neo 不限制您预订在线面授课的频率。然而，为了有效的学习，我们建议您至少每两 周预订一次在线面授课。<br>
          5.4 支持的浏览器:<br>
          <div style="margin:0 0 0 20px;">
            5.4.1. 谷歌浏览器(Google Chrome)63.xx 或更高版本(PC 或移动设备)<br>
            5.4.2 火狐浏览器(Firefox)58.xx 或更高版本(PC 或移动设备)<br>
            5.4.3 Safari 11.xx 或更高版本(PC 或移动设备)<br>
          </div>
          5.5 硬件推荐:<br>
          <div style="margin:0 0 0 20px;">
            配有摄像头的 PC/笔记本/移动设备<br>
            至少 512/kbps 的专用互联网连接
          </div>
        </div>
      </p>
      <p class="eula">
        <br><b>服务的终止</b><br><br>
        除非您或者 Nexgen 公司终止本协议，否则本协议将一直有效。在任何时间，Nexgen 公司
        可全权酌情决定是否通知您以任何理由或无理由暂停或终止本协议。如果您违反了协议的任 何条款或条件，本协议将自动终止，恕不另行通知。您也可以通过从移动设备或计算机中删 除应用程序及其所有副本来终止本协议。本协议终止后，您应停止对本应用程序的所有使用， 并从您的移动设备或计算机中删除该应用程序的所有副本。如果您在协议有效期内违反了本 协议下的任何规定，则本协议的终止将不限制 Nexgen 公司追究法律或权益方面的任何权 利或赔偿。

        <br><br><b>协议补充条款</b><br><br>
        Nexgen 公司保留自行决定随时修改或替换本协议的权利。如有重大修订，Nexgen 将在新 条款生效前至少提前 30 天通知您。构成重大变更的内容将由 Nexgen 自行决定。在任何修 订生效后继续访问或使用 Nexgen 的应用程序，即表示您同意受修订条款的约束。如果您不 同意新条款，则您无权再使用该应用程序。

        <br><br><b>免责条款</b><br><br>
        您明确承认并同意，自行承担本应用程序的使用风险。在适用法律允许的最大范围内，本应 用程序以及应用程序执行或提供的任何服务均按“原样”和“按可用情况”提供，包括所有错误 且无任何形式的保证。Nexgen 特此声明不承担任何保证和关于本应用程序和任何明示、暗 示或法定的服务条款，其中包括但不限于对适销性、令人满意的质量、适合特定目的的适用 性、准确性、安静性暗示的保证和/或条件，享受且不侵犯第三方的权利。 Nexgen 或其授 权代表提供的任何口头或书面信息或建议均不构成保证。如果应用程序或服务证明有缺陷， 您将承担所有必要服务、维修或更正的全部费用。某些司法管辖区不允许排除对消费者适用 法定权利的默认保证或限制，因此上述排除和限制可能不适用于您。

        <br><br><b>责任范围</b><br><br>
        在法律允许范围内的任何情况下，Nexgen 都不对任何由应用程序使用或无法使用应用程序
        而引起的任何附带、特殊、间接伤害或间接损失承担任何责任，包括但不限于利益损失、商 业信息丢失、运营中断、或赔偿责任理论(合同、侵权或其他)，即使 Nexgen 已知悉了这 种伤害存在的可能性。部分司法管辖区不允许限制人身伤害或偶发或间接损失的责任，因此， 上述例外情况可能不适用于您。在任何情况下，Nexgen 承担的和应用程序伤害相关的责任 (适用法律可能会导致人身伤害的除外)都不超过您购买该软件实际支付的金额。即使上述 补救措施未能达到其基本目的，上述限制仍然适用。

        <br><br><b>法律依据</b><br><br>
        本协议和您对本应用程序的使用应以美国加利福尼亚州的法律(不包括其法律冲突规则)为 准。您对应用程序的使用也可能受其他地方、州、国家或国际法律的约束。

        <br><br><b>完整协议</b><br><br>
        本协议构成您与 Nexgen 之间关于您对本应用程序使用的全部协议，并取代您与 Nexgen 之 间所有之前和当下的书面或口头协议。

        <br><br><b>联系信息</b><br><br>
        如果您对此协议有疑问，请通过以下方式与我们联系:<br>
        legal@nexgenenglishonline.co
      </p>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .eula {
    text-align: justify;

    b {
      font-weight: bold;
    }
  }
</style>
